<template>
  <div class="stat-box">
    <p class="stat-box__value">
      {{ stat.value }}
      <span v-if="stat.unit">/{{ stat.unit }}</span>
    </p>
    <p class="stat-box__label">
      {{ stat.label }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    stat: {
      type: Object,
      default () {
        return {
          value: '',
          unit: '',
          label: ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .stat-box {
    width: calc(33% - .5rem);
    margin: 0;
    margin-bottom: .5rem;
    padding: 1.5rem 0;
    background: $white-matter;
    border: 1px solid $axon;
    border-radius: 2px;
    display: grid;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $breakpoint-mobile) {
      width: 100%;
      margin: 1rem 0rem 0;
    }
  }

  .stat-box__value,
  .stat-box__label {
    display: block;
    margin: 0;
    text-align: center;
  }

  .stat-box__value {
    @include text-style('title', 'extra-large', 'bold');

    span {
      @include text-style('interface', 'extra-small', 'regular');
    }
  }

  .stat-box__label {
    @include text-style('interface', 'medium', 'regular');
  }
</style>
