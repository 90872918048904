<template>
  <div class="stat-boxes">
    <StatBox
      v-for="(stat, index) in stats"
      :key="index"
      v-loading="stat.loading"
      :stat="stat"
    />
  </div>
</template>

<script>
import StatBox from './StatBox'

export default {
  components: {
    StatBox
  },
  props: {
    stats: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .stat-boxes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    @media screen and (max-width: $breakpoint-tablet) {
      flex-wrap: wrap;
    }
  }
</style>
