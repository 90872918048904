import GET_STUDIES_QUERY from '@/graphql/studies/GetStudiesQuery.graphql'
import { logError } from '@/utils/logging'

export default {
  apollo: {
    studies() {
      return {
        query: GET_STUDIES_QUERY,
        update: data => data.getStudies,
        error (error) {
          logError(error, 'studies query')
        }
      }
    }
  },
  data() {
    return {
      studies: []
    }
  }
}
