var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"study-overview"},[_c('PageHeader',{attrs:{"title":_vm.getStudyName(),"back-link":{ text: 'All Studies', route: { name: 'studies' } },"tab-links":[
      { text: 'Study Overview', route: { name: 'studyOverview', params: { studyId: this.$route.params.studyId } } },
      { text: 'Participants', route: { name: 'studyParticipants', params: { studyId: this.$route.params.studyId } } },
      { text: 'Users', route: { name: 'studyUsers', params: { studyId: this.$route.params.studyId } } } ]}}),_c('StatBoxes',{attrs:{"stats":_vm.stats}}),(_vm.cohorts && _vm.cohorts.length > 0)?_c('ContentCard',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$apollo.queries.cohorts.loading),expression:"$apollo.queries.cohorts.loading"}]},[_c('h3',{staticClass:"content-card-title"},[_vm._v(" Cohort Breakdown ")]),_c('BfBarChart',{staticClass:"cohort-chart",attrs:{"chart-data":_vm.cohortChartData,"y-max":_vm.cohortChartMax,"y-label":"Participants","display-legend":""}})],1):_vm._e(),_c('div',{staticClass:"subgroup-information"},[_c('ContentCard',{staticClass:"pd-enrolled-chart"},[_c('h3',{staticClass:"content-card-title"},[_vm._v(" PD Enrollment Subgroup Breakdown ")]),_c('BfBarChart',{attrs:{"chart-data":_vm.parkinsonsCohortEnrollmentChartData,"height":315,"y-max":_vm.parkinsonsChartMax,"y-label":"Participants"}})],1),_c('ContentCard',{staticClass:"prodromal-enrolled-chart"},[_c('h3',{staticClass:"content-card-title"},[_vm._v(" Prodromal Enrollment Subgroup Breakdown ")]),_c('BfBarChart',{attrs:{"chart-data":_vm.prodromalCohortEnrollmentChartData,"height":315,"y-max":_vm.prodromalChartMax,"y-label":"Participants"}})],1),_c('ContentCard',{staticClass:"pd-enrolled-table"},[_c('h3',{staticClass:"content-card-title"},[_vm._v(" PD Enrollment Subgroup Intersection ")]),_c('BfScrollEffect',[_c('BfTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$apollo.queries.cohorts.loading),expression:"$apollo.queries.cohorts.loading"}],attrs:{"columns":[
            { name: 'subgroups', label: 'Enrollment Subgroups', sortable: true, minWidth: 185 },
            { name: 'participantCount', label: 'Count Participants', sortable: true, maxWidth: 165 }
          ],"data":_vm.parkinsonsCohortEnrollmentTableData,"caption":"PD Enrollment Subgroup Intersection"}})],1)],1),_c('ContentCard',{staticClass:"prodromal-enrolled-table"},[_c('h3',{staticClass:"content-card-title"},[_vm._v(" Prodromal Enrollment Subgroup Intersection ")]),_c('BfScrollEffect',[_c('BfTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$apollo.queries.cohorts.loading),expression:"$apollo.queries.cohorts.loading"}],attrs:{"columns":[
            { name: 'subgroups', label: 'Enrollment Subgroups', sortable: true, minWidth: 185 },
            { name: 'participantCount', label: 'Count Participants', sortable: true, maxWidth: 165 }
          ],"data":_vm.prodromalCohortEnrollmentTableData,"caption":"Prodromal Enrollment Subgroup Intersection"}})],1)],1)],1),_c('ContentCard',{staticClass:"sites"},[_c('h3',{staticClass:"sites__heading"},[_vm._v(" Sites ")]),_c('div',{staticClass:"sites__table"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$apollo.queries.sites.loading),expression:"$apollo.queries.sites.loading"}],attrs:{"data":_vm.sites,"border":""}},[_c('el-table-column',{attrs:{"prop":"customerDefinedId","label":"Site ID","min-width":"80","resizable":false,"sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Site Name","min-width":"220","resizable":false,"sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(row.name))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"protocol","label":"Protocol","width":"95","resizable":false,"sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getProtocolVersion(row))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"totalParticipantsCount","label":"Total Participants","sort-by":"totalParticipantsCount","min-width":"160","resizable":false,"sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}},{key:"default",fn:function(ref){
          var totalParticipantsCount = ref.row.totalParticipantsCount;
return [_c('span',[_vm._v(_vm._s(totalParticipantsCount))])]}}])}),_c('el-table-column',{attrs:{"prop":"enrolledParticipantsCount","label":"Participants Enrolled","sort-by":"enrolledParticipantsCount","min-width":"180","resizable":false,"sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}},{key:"default",fn:function(ref){
          var enrolledParticipantsCount = ref.row.enrolledParticipantsCount;
return [_c('span',[_vm._v(_vm._s(enrolledParticipantsCount))])]}}])})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }