<template>
  <div class="study-overview">
    <PageHeader
      :title="getStudyName()"
      :back-link="{ text: 'All Studies', route: { name: 'studies' } }"
      :tab-links="[
        { text: 'Study Overview', route: { name: 'studyOverview', params: { studyId: this.$route.params.studyId } } },
        { text: 'Participants', route: { name: 'studyParticipants', params: { studyId: this.$route.params.studyId } } },
        { text: 'Users', route: { name: 'studyUsers', params: { studyId: this.$route.params.studyId } } },
      ]"
    />

    <StatBoxes :stats="stats" />

    <ContentCard
      v-if="cohorts && cohorts.length > 0"
      v-loading="$apollo.queries.cohorts.loading"
    >
      <h3 class="content-card-title">
        Cohort Breakdown
      </h3>
      <BfBarChart
        class="cohort-chart"
        :chart-data="cohortChartData"
        :y-max="cohortChartMax"
        y-label="Participants"
        display-legend
      />
    </ContentCard>

    <div class="subgroup-information">
      <ContentCard class="pd-enrolled-chart">
        <h3 class="content-card-title">
          PD Enrollment Subgroup Breakdown
        </h3>
        <BfBarChart
          :chart-data="parkinsonsCohortEnrollmentChartData"
          :height="315"
          :y-max="parkinsonsChartMax"
          y-label="Participants"
        />
      </ContentCard>
      <ContentCard class="prodromal-enrolled-chart">
        <h3 class="content-card-title">
          Prodromal Enrollment Subgroup Breakdown
        </h3>
        <BfBarChart
          :chart-data="prodromalCohortEnrollmentChartData"
          :height="315"
          :y-max="prodromalChartMax"
          y-label="Participants"
        />
      </ContentCard>
      <ContentCard class="pd-enrolled-table">
        <h3 class="content-card-title">
          PD Enrollment Subgroup Intersection
        </h3>
        <BfScrollEffect>
          <BfTable
            v-loading="$apollo.queries.cohorts.loading"
            :columns="[
              { name: 'subgroups', label: 'Enrollment Subgroups', sortable: true, minWidth: 185 },
              { name: 'participantCount', label: 'Count Participants', sortable: true, maxWidth: 165 }
            ]"
            :data="parkinsonsCohortEnrollmentTableData"
            caption="PD Enrollment Subgroup Intersection"
          />
        </BfScrollEffect>
      </ContentCard>
      <ContentCard class="prodromal-enrolled-table">
        <h3 class="content-card-title">
          Prodromal Enrollment Subgroup Intersection
        </h3>
        <BfScrollEffect>
          <BfTable
            v-loading="$apollo.queries.cohorts.loading"
            :columns="[
              { name: 'subgroups', label: 'Enrollment Subgroups', sortable: true, minWidth: 185 },
              { name: 'participantCount', label: 'Count Participants', sortable: true, maxWidth: 165 }
            ]"
            :data="prodromalCohortEnrollmentTableData"
            caption="Prodromal Enrollment Subgroup Intersection"
          />
        </BfScrollEffect>
      </ContentCard>
    </div>

    <ContentCard class="sites">
      <h3 class="sites__heading">
        Sites
      </h3>
      <div class="sites__table">
        <el-table
          v-loading="$apollo.queries.sites.loading"
          :data="sites"
          border
        >
          <el-table-column
            prop="customerDefinedId"
            label="Site ID"
            min-width="80"
            :resizable="false"
            sortable
          >
            <template v-slot:header="{ column }">
              {{ column.label }}
              <Caret :order="column.order" />
            </template>
          </el-table-column>

          <el-table-column
            prop="name"
            label="Site Name"
            min-width="220"
            :resizable="false"
            sortable
          >
            <template v-slot:header="{ column }">
              {{ column.label }}
              <Caret :order="column.order" />
            </template>
            <template v-slot="{ row }">
              {{ row.name | capitalize }}
            </template>
          </el-table-column>

          <el-table-column
            prop="protocol"
            label="Protocol"
            width="95"
            :resizable="false"
            sortable
          >
            <template v-slot:header="{ column }">
              {{ column.label }}
              <Caret :order="column.order" />
            </template>
            <template v-slot="{ row }">
              {{ getProtocolVersion(row) }}
            </template>
          </el-table-column>

          <el-table-column
            prop="totalParticipantsCount"
            label="Total Participants"
            sort-by="totalParticipantsCount"
            min-width="160"
            :resizable="false"
            sortable
          >
            <template v-slot:header="{ column }">
              {{ column.label }}
              <Caret :order="column.order" />
            </template>
            <template v-slot="{ row: { totalParticipantsCount } }">
              <span>{{ totalParticipantsCount }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="enrolledParticipantsCount"
            label="Participants Enrolled"
            sort-by="enrolledParticipantsCount"
            min-width="180"
            :resizable="false"
            sortable
          >
            <template v-slot:header="{ column }">
              {{ column.label }}
              <Caret :order="column.order" />
            </template>
            <template v-slot="{ row: { enrolledParticipantsCount } }">
              <span>{{ enrolledParticipantsCount }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </ContentCard>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader/PageHeader'
import StatBoxes from '@/components/StatBoxes/StatBoxes'
import ContentCard from '@/components/ContentCard/ContentCard'
import Caret from '@/components/Caret/Caret'
import BfScrollEffect from '@/components/BfScrollEffect/BfScrollEffect'
import BfBarChart from '@/components/BfBarChart/BfBarChart'
import BfTable from '@/components/BfTable/BfTable'
import GET_SITES_QUERY from '@/graphql/sites/GetSitesQuery.graphql'
import GET_COHORTS_QUERY from '@/graphql/cohorts/GetStudyCohortsQuery.graphql'
import studies from '@/mixins/queries/studies'
import { ParticipantProtocolStatus } from '@/utils/constants'
import { logError } from '@/utils/logging'
import { compose, prop, sortBy, toLower } from 'ramda'

const PRIMARY_BAR_COLOR = '#2052E3' // Dopastat
const SECONDARY_BAR_COLOR = '#10359C' // Yaba

const sortByName = sortBy(
  compose(
    toLower,
    prop('name')
  )
)

export default {
  components: {
    PageHeader,
    ContentCard,
    StatBoxes,
    BfBarChart,
    Caret,
    BfScrollEffect,
    BfTable
  },
  mixins: [ studies ],
  apollo: {
    sites() {
      return {
        query: GET_SITES_QUERY,
        variables: {
          studyId: this.studyId,
          withParticipantCount: true
        },
        update: data => data.getSites.map(site => ({
          ...site,
          totalParticipantsCount: this.getTotalParticipants(site),
          enrolledParticipantsCount: this.getEnrolledParticipants(site)
        })),
        error (error) {
          logError(error, 'StudyOverview.vue sites query')
        }
      }
    },

    cohorts() {
      return {
        query: GET_COHORTS_QUERY,
        variables: {
          studyId: this.studyId,
          withExcludedCohorts: false
        },
        update: data => sortByName(data.getStudyCohorts),
        error (error) {
          logError(error, 'StudyOverview.vue cohorts query')
        }
      }
    }
  },

  data () {
    return {
      sites: [],
      cohorts: []
    }
  },
  computed: {
    parkinsonsDiseaseCohort() {
      return this.cohorts.find(cohort => cohort.name === "Parkinson's Disease")
    },
    prodromalCohort() {
      return this.cohorts.find(cohort => cohort.name === 'Prodromal')
    },
    totalEnrolledPDParticipantsWithNoSubgroup() {
      if (!this.parkinsonsDiseaseCohort) return 0

      const totalEnrolledParticipantsWithSubgroup =
        this.parkinsonsCohortEnrollmentTableData.reduce((count, data) => count + data.participantCount, 0)
      const totalEnrolledParticipants =
        this.parkinsonsDiseaseCohort.participantCountByStatus
          .find(statusCount => statusCount.status === ParticipantProtocolStatus.ENROLLED)
          .count

      return totalEnrolledParticipants - totalEnrolledParticipantsWithSubgroup
    },
    parkinsonsCohortEnrollmentChartData() {
      return this.getCohortSubgroupEnrollmentChartData(this.parkinsonsDiseaseCohort)
    },
    parkinsonsCohortEnrollmentTableData() {
      return this.getCohortSubgroupEnrollmentTableData(this.parkinsonsDiseaseCohort)
    },
    prodromalCohortEnrollmentChartData() {
      return this.getCohortSubgroupEnrollmentChartData(this.prodromalCohort)
    },
    prodromalCohortEnrollmentTableData() {
      return this.getCohortSubgroupEnrollmentTableData(this.prodromalCohort)
    },
    parkinsonsChartMax() {
      if (!this.parkinsonsDiseaseCohort) return

      return this.getChartMax(
        this.parkinsonsDiseaseCohort.enrolledParticipantCountBySubgroup.map(count => count.participantCount)
      )
    },
    prodromalChartMax() {
      if (!this.prodromalCohort) return

      return this.getChartMax(
        this.prodromalCohort.enrolledParticipantCountBySubgroup.map(count => count.participantCount)
      )
    },
    /*
     * This makes it easier to work with the studyId
     */
    studyId() {
      return this.$route.params.studyId
    },

    study() {
      return this.studies.find(s => s.id === this.studyId)
    },

    totalParticipants() {
      return this.cohorts.reduce((total, cohort) => total + cohort.participantCount, 0)
    },

    enrolledParticipants() {
      return this.cohorts.reduce((total, cohort) => total + this.getEnrolledParticipants(cohort), 0)
    },

    stats() {
      return [
        {
          label: 'Clinical Sites',
          value: this.sites.length,
          loading: this.$apollo.queries.sites.loading
        },
        {
          label: 'Total Participants ',
          value: this.totalParticipants,
          loading: this.$apollo.queries.cohorts.loading
        },
        {
          label: 'Enrolled Participants ',
          value: this.enrolledParticipants,
          loading: this.$apollo.queries.cohorts.loading
        }
      ]
    },

    cohortChartData() {
      if (this.cohorts.length > 0) {
        const chartData = {
          labels: [],
          datasets: [
            {
              label: 'Participants',
              data: [],
              /* visualization of bar layout:
               *
               * bar %:           |.85||.85|
               * category %:      |   .5   |
               * full:        |=================|
               *
               * .85 bar% ensures there is some space between the bars on smaller screens (iPad)
               * Due to the responsive nature of the chart, the gap grows as the window size increases.
               */
              barPercentage: 0.85,
              categoryPercentage: 0.5,
              maxBarThickness: 64,
              backgroundColor: SECONDARY_BAR_COLOR
            },
            {
              label: 'Enrolled Participants',
              data: [],
              barPercentage: 0.85,
              categoryPercentage: 0.5,
              maxBarThickness: 64,
              backgroundColor: PRIMARY_BAR_COLOR
            }
          ]
        }
        this.cohorts.forEach(cohort => {
          // add label & datasets
          chartData.labels.push(cohort.name)
          chartData.datasets[0].data.push(cohort.participantCount)
          chartData.datasets[1].data.push(this.getEnrolledParticipants(cohort))
        })
        return chartData
      }
      return {}
    },

    cohortChartMax() {
      if (!this.cohorts.length) return
      return this.getChartMax(this.cohorts.map(cohort => cohort.participantCount))
    }
  },
  methods: {
    /*
     * find the highest value and round up to the nearest 10
     */
    getChartMax(dataset) {
      if (dataset.length) {
        return Math.ceil((Math.max(...dataset) + 1) / 10) * 10
      }
      return 10
    },
    getCohortSubgroupEnrollmentChartData(cohort) {
      return cohort ? ({
        labels: cohort.enrolledParticipantCountBySubgroup.map(count => count.name),
        datasets: [{
          label: 'Participants',
          data: cohort.enrolledParticipantCountBySubgroup.map(count => count.participantCount),
          maxBarThickness: 64,
          backgroundColor: PRIMARY_BAR_COLOR
        }]
      }) : {}
    },
    getCohortSubgroupEnrollmentTableData(cohort) {
      return cohort ? cohort.enrolledParticipantCountBySubgroupCombination.map(subgroupData => ({
        participantCount: subgroupData.participantCount,
        subgroups: subgroupData.subgroups.map(subgroup => subgroup.name).join(', ')
      })) : []
    },
    getEnrolledParticipants(siteOrCohort) {
      if (siteOrCohort.participantCountByStatus) {
        const enrolledParticipants = siteOrCohort.participantCountByStatus.find(
          _status => _status.status === ParticipantProtocolStatus.ENROLLED
        )
        return enrolledParticipants ? enrolledParticipants.count : 0
      }
      return 0
    },

    // add up all participants from each status count
    getTotalParticipants(site) {
      if (site.participantCountByStatus) {
        return site.participantCountByStatus.reduce((total, status) => total + status.count, 0)
      }
      return 0
    },

    // Get the latest protocol version
    getProtocolVersion(site) {
      let latestProtocol = 0
      site.protocolVersions.forEach(version => {
        if (version.protocolVersion.versionName > latestProtocol) {
          latestProtocol = version.protocolVersion.versionName
        }
      })
      return latestProtocol
    },

    getStudyName() {
      return this.study?.name
    }
  }
}
</script>

<style lang="scss">
  .prodromal-enrolled-table, .pd-enrolled-table {
    &.content-card {
      padding: 0;
    }

    .content-card-title {
      padding: 1rem 0 0 1rem;
    }
  }

  .content-card-title {
    margin: 0 0 1rem 0;
  }

  .subgroup-information {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 23.625rem 23.625rem;
    grid-auto-flow: row;
    grid-gap: 1rem;
    margin: 1rem 1rem 1rem 0;

    .content-card {
      margin: 0;
    }
  }

  .cohort-chart {
    margin: 0 auto;
    padding: 0;
    overflow: visible;
  }

  .sites {
    &__heading {
      margin-top: 0;
      margin-bottom: 1rem;
      @include text-weight('medium');
    }

    &__table {
      margin-left: -1rem;
      margin-right: -1rem;
      margin-bottom: -1rem;
    }

    .el-table {
      border-left: 0;
      border-top: 0;

      &.el-table--fit {
        border: 0;
      }

      th {
        border-right: 0;
      }
    }

    // hides the bottom and right borders
    .el-table::before,
    .el-table::after {
      display: none;
    }

    th:first-child,
    td:first-child {
      border-left: none;
    }

    th:last-child,
    td:last-child {
      border-right: none;
    }

    tr:last-child td {
      border-bottom: 0;
    }
  }
</style>
