<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    yMax: {
      type: Number,
      default: 100
    },
    yLabel: {
      type: String,
      default: null
    },
    displayLegend: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: this.displayLegend,
          position: 'bottom',
          labels: {
            boxWidth: 12
          }
        },
        defaultFontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        scales: {
          yAxes: [
            {
              type: 'linear',
              scaleLabel: {
                display: true,
                fontColor: '#000', // $black
                fontSize: 14,
                fontStyle: 'bold',
                labelString: this.yLabel
              },
              ticks: {
                beginAtZero: true,
                max: this.yMax,
                fontColor: '#000' // $black
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                fontColor: '#000' // $black
              }
            }
          ]
        }
      }
    }
  },
  mounted () {
    // this.chartData is created in the mixin.
    this.renderChart(this.chartData, this.options)
  }
}
</script>
